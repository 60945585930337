<!-- 饼图 -->
<template>
  <div class="pie-container">
    <div class="pie">
      <!-- echarts -->
      <div :id="pieName" class="main"></div>
      <el-button
        v-if="subTxt == '累计店铺总数'"
        @click="
          () => {
            collect(
              'totalShopNum',
              '累计店铺数',
              [
                {
                  name: '所属客户',
                  type: 'input',
                  modelKey: 'customerName'
                }
              ],
              [
                { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '关联订单数量', colProp: ['orderNum'] }
              ]
            );
          }
        "
        type="text"
        :class="[subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop']"
        >{{ centerTxt }}</el-button
      >
      <el-button
        v-if="pieName == 'main2'"
        @click="
          () => {
            collect(
              'serviceLoginShopNum',
              '服务中应上号店铺总数',
              [
                {
                  name: '店铺名称',
                  type: 'input',
                  modelKey: 'shopName'
                },
                { name: '所属客户', type: 'input', modelKey: 'customerName' },
                {
                  name: '所属平台',
                  type: 'platSelect',
                  modelKey: 'platformId',
                  selOpt: this.platList
                }
              ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '所属平台', colProp: ['platformName'] },
                { name: '关联订单数', colProp: ['orderNum'] }
              ]
            );
          }
        "
        type="text"
        :class="[subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop']"
        >{{ centerTxt }}</el-button
      >
      <el-button
        v-if="pieName == 'main3'"
        @click="
          () => {
            collect(
              'earlyWarningNum',
              '当前预警总数',
              [
                {
                  name: '预警编号',
                  type: 'input',
                  modelKey: 'warningCode'
                },
                {
                  name: '店铺名称',
                  type: 'input',
                  modelKey: 'shopName'
                },
                {
                  name: '子账号名称',
                  type: 'input',
                  modelKey: 'subAccountName'
                },
                {
                  name: '客服所属公司',
                  type: 'input',
                  modelKey: 'serviceCompanyId'
                },
                {
                  name: '处理状态',
                  type: 'select',
                  modelKey: 'dealStatus',
                  selOpt: [
                    { id: 'unDeal', name: '未处理' },
                    { id: 'deal', name: '已处理' }
                  ]
                }
              ],
              [
                { name: '预警编号', colProp: ['warningCode'] },
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '子账号名称', colProp: ['subAccountName'] },
                { name: '负责客服', colProp: ['adminNames'] },
                { name: '客服所属公司', colProp: ['companyName'] },
                { name: '处理状态 ', colProp: ['dealStatus'] }
              ]
            );
          }
        "
        type="text"
        :class="[subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop']"
        >{{ centerTxt }}</el-button
      >
      <el-button
        v-if="pieName == 'main4'"
        @click="
          () => {
            collect(
              'shouldQualityShopNum',
              '应质检店铺数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },

                {
                  name: '所属客户',
                  type: 'customerScreen',
                  modelKey: 'customerId',
                  selOpt: rolesCompany
                }
              ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '累计质检次数', colProp: ['qualityCount'] }
              ],
              selectDate
            );
          }
        "
        type="text"
        :class="[subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop']"
        >{{ centerTxt }}</el-button
      >
      <el-button
        v-if="pieName == 'main5'"
        @click="
          () => {
            collect(
              'totalPrice',
              '订单应收金额',
              [
                { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                { name: '客户名称', type: 'input', modelKey: 'customerName' }
              ],
              [
                { name: '订单编号', colProp: ['orderNo'] },
                { name: '订单名称', colProp: ['orderName'] },
                { name: '客户名称', colProp: ['customerName'] },
                { name: '服务时间', colProp: ['startTime', 'endTime'] },
                { name: '应收金额', colProp: ['price'] },
                { name: '销售负责人', colProp: ['createName'] },
                { name: '备注', colProp: ['note'] }
              ]
            );
          }
        "
        type="text"
        :class="[subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop']"
        >
        <!-- {{ subTxt == '订单应收总金额' ? '￥'+(Math.round(Number(centerNum) / 10000))+'w':centerTxt }} -->
        {{ subTxt == '订单应收总金额' ? (centerNum.split('.')[0].length >5 ? '￥'+(Math.round(Number(centerNum) / 10000))+'w': (Number(centerNum) / 10000).toFixed(2) + 'w'):centerTxt }}
        </el-button
      >
      <el-button
        v-if="pieName == 'main6'"
        @click="
          () => {
            collect(
              'clockNum',
              '累计打卡人数',
              [
                
                { name: '员工名称', type: 'input', modelKey: 'customerName' }
              ],
              [
                { name: '员工名称', colProp: ['name'] },
                { name: '所属公司', colProp: ['companyName'] },
                { name: '所属部门', colProp: ['departName'] },
                { name: '班次', colProp: ['frequencyName'] },
                { name: '上班打卡时间', colProp: ['time1'] },
                { name: '下班打卡时间', colProp: ['time2'] }
              ]
            );
          }
        "
        type="text"
        :class="[subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop']"
        >{{ centerTxt }}</el-button
      >
    </div>

    <el-tooltip
      class="item"
      effect="dark"
      :content="centerTxt"
      placement="bottom"
    >
      <el-button
        v-if="pieName == 'main5'"
        @click="
          () => {
            collect(
              'totalPrice',
              '订单应收金额',
              [
                { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                { name: '客户名称', type: 'input', modelKey: 'customerName' }
              ],
              [
                { name: '订单编号', colProp: ['orderNo'] },
                { name: '订单名称', colProp: ['orderName'] },
                { name: '客户名称', colProp: ['customerName'] },
                { name: '服务时间', colProp: ['startTime', 'endTime'] },
                { name: '应收金额', colProp: ['price'] },
                { name: '销售负责人', colProp: ['createName'] },
                { name: '备注', colProp: ['note'] }
              ]
            );
          }
        "
        type="text"
        :class="[subTxt == '订单应收总金额' ? 'totalShop2' : 'totalShop']"
        >
        <!-- {{ subTxt == '订单应收总金额' ? '￥'+(Math.round(Number(centerNum) / 10000))+'w':centerTxt }} -->
        {{ subTxt == '订单应收总金额' ? (centerNum.split('.')[0].length >5 ? '￥'+(Math.round(Number(centerNum) / 10000))+'w': (Number(centerNum) / 10000).toFixed(2) + 'w'):centerTxt }}
        </el-button
      >
    </el-tooltip>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="
        pieName == 'main'
          ? getMarketReportList
          : pieName == 'main2'
          ? customerUp
          : pieName == 'main3'
          ? customerUp
          : pieName == 'main4'
          ? getQualityReportList
          : pieName == 'main5'
          ? getFinanceReportDetail
          : pieName == 'main6'
          ? getReportDetail
          : ''
      "
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../../../utils/index.js";
import { getPlatList } from "../../../service/customer.js";
import CommonSumDialog from "../../../components/common/commonSumDialog.vue";
import {
  getMarketReportList,
  customerUp,
  getQualityReportList,
  getFinanceReportDetail,
  getReportDetail
} from "../../../service/home.js";
import elementResizeDetectorMaker from "element-resize-detector"; // 尺寸监听组件

export default {
  name: "",
  components: { CommonSumDialog },
  // 圆环中心大标题  圆环中心小标题   类名  颜色        数据
  props: [
    "centerTxt",
    "subTxt",
    "pieName",
    "pieColor",
    "pieData",
    "selectDate",
    "comId"
  ],
  data() {
    return {
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getMarketReportList, // 列表接口方法
      customerUp,
      getQualityReportList,
      getFinanceReportDetail,
      getReportDetail,
      platList: [], // 所属平台/类目下拉
      params: {},
      divWidth: "",
      rolesCompany: this.$sto.get(Config.constants.userInfo),
    };
  },
  //监听属性 类似于data概念
  computed: {
    centerNum(){
      return (this.centerTxt.split('￥')[this.centerTxt.split('￥').length-1])
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 圆环图
    drawPie() {
      // echarts饼图
      let myChart = this.$echarts.init(document.getElementById(this.pieName));
      myChart.setOption({
        title: {
          text: "\t", //主标题文本
          subtext: this.subTxt, //副标题文本
          left: "center",
          top: "38%",
          textStyle: {
            fontSize: 34,
            color: "#333",
            align: "center"
          },
          subtextStyle: {
            fontSize: 14,
            color: "#333"
          }
        },
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            hoverAnimation: false, // 取消掉环形图鼠标移上去时自动放大
            // name: "访问来源",
            type: "pie",
            radius: ["75%", "100%"], //第一个值控制空心圆大小 第二个值控制外圆大小
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: this.pieData
          }
        ],
        color: this.pieColor,
        animation: true,
        animationThreshold: 1500
      });
    },
    reSize() {
      console.log("00000");
      let myChart = this.$echarts.init(document.getElementById(this.pieName));
      console.log(myChart, "000111");
      // let chart = document.getElementById(this.pieName)

      window.onresize = function() {
        //重置容器高宽
        myChart.style.width = this.divWidth + "px";
        myChart.style.height = this.divWidth + "px";

        console.log("1111");
        myChart.resize();
      };
    },
    async getPlatList() {
      // 所属平台/类目下拉
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr, date) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.comId,
        "",
        "",
        "",
        date
      );
      this.commonSumVisible = true;
    },
    getWidth() {
      this.divWidth = document.getElementById(this.pieName).clientWidth;
      console.log(
        document.getElementById(this.pieName).clientWidth,
        "呦呦切克闹"
      );
      // this.reSize()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPlatList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.drawPie();

    var mainChart = document.getElementById(this.pieName);
    let charts = this.$echarts.init(mainChart); // 图标ID初始化--使用它的resize()
    // 初始化element-resize-detector组件
    let erd = elementResizeDetectorMaker({
      strategy: "scroll", // <- 推荐监听滚动，提升性能
      callOnAdd: true // 添加侦听器时是否应调用,默认true
    })
    //监听id的元素 大小变化 参数element
    erd.listenTo(mainChart, function() {
      // let width = element.offsetWidth;
      // let height = element.offsetHeight;
      // console.log(element,'........');
      charts.resize(); // 当元素尺寸发生改变是会触发此事件，刷新图表
    });
  }
};
</script>

<style lang="less" scoped>
.pie-container {
  position: relative;
  /deep/.item {
    cursor: pointer;
    display: inline-block;
    width: 150px;
    text-align: center;
    position: absolute;
    top: 68px;
    left: 42px;
    text-decoration: underline;
    text-decoration-color: #333333;
    span {
      display: inline-block;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 28px;
      color: #333333;
      border-bottom: 2px solid #333;

      // text-decoration: underline;
      // text-decoration-color: #333333;
    }
  }
  .pie {
    position: relative;
    margin-right: 40px;
    .main {
      width: 240px;
      height: 240px;
    }
    /deep/.totalShop {
      cursor: pointer;
      display: inline-block;
      text-decoration: underline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      text-align: center;
      position: absolute;
      top: 68px;
      left: 20px;
      text-decoration-color: #333333;
      span {
        font-size: 34px;
        color: #333333;
      }
    }
    /deep/.totalShop2 {
      cursor: pointer;
      display: inline-block;
      text-decoration: underline;
      text-decoration-color: #333333;
      width: 150px;
      text-align: center;
      position: absolute;
      top: 68px;
      left: 42px;
      span {
        display: inline-block;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 28px;
        color: #333333;
        border-bottom: 2px solid #333;
        //   text-decoration: underline;
        // text-decoration-color: #333333;
      }
    }
    .tit-img {
      margin-left: 4px;
      vertical-align: middle;
    }
    .shopExplain {
      position: absolute;
      top: 133px;
      left: 76px;
      width: 89px;
      text-align: center;
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
    }
  }
}
</style>
